import React from 'react';
import { navigate } from 'gatsby-link';
import { Link } from 'gatsby';
import GoogleMapReact from 'google-map-react';
import SiteLayout from '@layouts/site-layout';

function encode(data) {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
        .join('&')
}
 
const AnyReactComponent = ({ text }) => (<div 
    style={{
        color: 'white', 
        background: 'grey',
        padding: '15px 10px',
        display: 'inline-flex',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '100%',
        transform: 'translate(-50%, -50%)'
      }}
>{text}</div>);

// class SimpleMap extends Component {
//     static defaultProps = {
//     center: {
//     lat: 59.95,
//     lng: 30.33
//     },
//     zoom: 11
// };
// };

export default class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = { isValidated: false }
    }

    static defaultProps = {
        center: {
        lat: 19.130584,
        lng: 72.844643 
        },
        zoom: 14
    };

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
        e.target.setCustomValidity("")
    }

    handleSubmit = e => {
        e.preventDefault()
        const form = e.target
        fetch('/', {
            method: 'POST',
            headers: {'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({
                'form-name': form.getAttribute('name'),
                ...this.state,
            }),
        })
            .then(() => navigate(form.getAttribute('action')))
            .catch(error => alert(error))
    }
    // AIzaSyC4R6AN7SmujjPUIGKdyao2Kqitzr1kiRg&v=3.exp&libraries=geometry,drawing,places

    render() {
        let description =  `STEPapp, created by a team of 400+ IITians and Doctors after extensive research, is a unique
        e-learning software product that provides conceptual content delivery in a gamified format. Our
        team comes with deep experience and expertise gathered over many years of teaching and
        training students from various socio-economic strata of the country, preparing them for various
        reputed national and international competitive exams, and turning them into the best and
        brightest this country has ever seen.`
        let img = `img/stepapp_logo.png`
        return ( 

            <SiteLayout title="Contact Us" description={description} image={img}>
                {/* <section className="section top_margin_comn">
                    <div className="container">
                        <div className="content">
                            <h1>Contact</h1>
                            <form
                                name="contact"
                                method="post"
                                action="/contact/thanks/"
                                data-netlify="true"
                                data-netlify-honeypot="bot-field"
                                onSubmit={this.handleSubmit}
                            >
                                 
                                <input type="hidden" name="form-name" value="contact" />
                                <div hidden>
                                    <label>
                                        Don’t fill this out:{' '}
                                        <input name="bot-field" onChange={this.handleChange} />
                                    </label>
                                </div>
                                <div className="field">
                                    <label className="label" htmlFor={'name'}>
                                        Your name
                                    </label>
                                    <div className="control">
                                        <input
                                            className="input"
                                            type={'text'}
                                            name={'name'}
                                            onChange={this.handleChange}
                                            id={'name'}
                                            required={true}
                                        />
                                    </div>
                                </div>
                                <div className="field">
                                    <label className="label" htmlFor={'email'}>
                                        Email
                                    </label>
                                    <div className="control">
                                        <input
                                            className="input"
                                            type={'email'}
                                            name={'email'}
                                            onChange={this.handleChange}
                                            id={'email'}
                                            required={true}
                                        />
                                    </div>
                                </div>
                                <div className="field">
                                    <label className="label" htmlFor={'message'}>
                                        Message
                                    </label>
                                    <div className="control">
                                        <textarea
                                            className="textarea"
                                            name={'message'}
                                            onChange={this.handleChange}
                                            id={'message'}
                                            required={true}
                                        />
                                    </div>
                                </div>
                                <div className="field">
                                    <button className="button is-link" type="submit">
                                        Send
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>   */}


    <div className="cont_form top_margin_comn">
         <div className="container">
            <div className="row">
               <div className="col-md-7">
                  <h3>We'd Love To Hear From You</h3>
                  <p>As educators, we love answering any questions that bother students, their parents, and our fellows. We look forward to hearing from you!
                  </p>
               </div>
               <div className="col-md-5">
                  {/* <form>
                     <div className="form-row">
                        <div className="form-group col-md-12"> 
                           <input type="email" className="form-control" placeholder="Name" required />
                        </div>
                        <div className="form-group col-md-12"> 
                           <input type="email" className="form-control" placeholder="Email" required />
                        </div>
                        <div className="form-group col-md-12"> 
                           <input type="email" className="form-control" placeholder="Subject" required />
                        </div> 
                        <div className="form-group col-md-12"> 
                           <input type="email" className="form-control" placeholder="Phone Nnmber" required />
                        </div> 
                        <div className="form-group col-md-12"> 
                        <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" placeholder="Message here" required></textarea>
                        </div> 
                     </div>   
                     <button type="submit" className="btn btn-primary">Sign in</button>
                  </form> */}
                   
                            {/* <h1>Contact</h1> */}
                            <div className="form-row"> 
                            <form
                                name="contact"
                                method="post"
                                action="/contact/thanks/"
                                data-netlify="true"
                                data-netlify-honeypot="bot-field"
                                onSubmit={this.handleSubmit}
                            >
                                <input type="hidden" name="form-name" value="contact" />
                                <div hidden>
                                    <label>
                                        Don’t fill this out:{' '}
                                        <input name="bot-field" onChange={this.handleChange} />
                                    </label>
                                </div>
                                <div className="field">
                                    <label className="label" htmlFor={'name'}>
                                        Your name *
                                    </label>
                                    <div className="control">
                                        <input
                                            className="input form-control"
                                            type={'text'}
                                            // required pattern="[A-Za-z]*"
                                            required  pattern="[A-z ']*"
                                            onInvalid={e => e.target.setCustomValidity("Enter Letters only")}
                                            name={'name'}
                                            onChange={this.handleChange}
                                            id={'name'}
                                            required={true}
                                        />
                                    </div>
                                </div>
                                <div className="field">
                                    <label className="label" htmlFor={'email'}>
                                        Email *
                                    </label>
                                    <div className="control">
                                        <input
                                            className="input form-control"
                                            required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                            type={'email'}
                                            name={'email'}
                                            onChange={this.handleChange}
                                            id={'email'}
                                            required={true}
                                        />
                                    </div>
                                </div>
                                <div className="field">
                                    <label className="label" htmlFor={'Subject'}>
                                        Subject
                                    </label>
                                    <div className="control">
                                        <input
                                            className="input form-control"
                                            required  pattern="[A-z ']*"
                                            type={'text'}
                                            name={'Subject'}
                                            onChange={this.handleChange}
                                            onInvalid={e => e.target.setCustomValidity("Enter Letters only")}
                                            id={'Subject'}
                                            required={true}
                                        />
                                    </div>
                                </div>
                                <div className="field">
                                    <label className="label" htmlFor={'Phone Number'}>
                                        Phone Number *
                                    </label>
                                    <div className="control">
                                        <input
                                            className="input form-control"
                                            required pattern="[0-9]*" 
                                            maxlength="10"
                                            minlength="8"
                                            type={'text'}
                                            name={'Phone Number'}
                                            onChange={this.handleChange}
                                            onInvalid={e => e.target.setCustomValidity(" Enter Numbers only")}
                                            id={'Phone Number'}
                                            required={true}
                                        />
                                    </div>
                                </div> 
                                <div className="field">
                                    <label className="label" htmlFor={'message'}>
                                        Message
                                    </label>
                                    <div className="control">
                                        <textarea
                                            className="textarea"
                                            name={'message'}
                                            onChange={this.handleChange}
                                            id={'message'}
                                            required={true}
                                        />
                                    </div>
                                </div>
                                <div className="field">
                                    <button className="button is-link btn btn-primary" type="submit">
                                        Send
                                    </button>
                        </div>
                     </form> 
                    </div>
               </div>
            </div>
         </div>
      </div> 

      <div className="map_section"> 
         <div className="container"> 
            <div style={{ height: '350px', width: '100%' }}>
                    <GoogleMapReact
                    bootstrapURLKeys={{
                        // key:'AIzaSyDh3qULzk-7pVIAgPT0n-QHfC8vFz-jVTU',
                        key: 'AIzaSyA6auGtZFH0r9U1uD0VYWDEgONqpm0OFIg',
                        language: 'en',
                        region: 'en', 
                     }}
                    defaultCenter={this.props.center} 
                    defaultZoom={12} 
                    // bootstrapURLKeys={{ key:'AIzaSyDh3qULzk-7pVIAgPT0n-QHfC8vFz-jVTU'}}
                    >
                    <AnyReactComponent
                        lat={19.124808}
                        lng={72.846489}    
                        text="EduIsFun"
                    />
                    </GoogleMapReact>
            </div>

            <div className="cont_address">
                <div>
                    <img src="img/location.png" alt="img_missing" />
                    <p>801, Business Point, opp. Andheri Subway, Andheri West,
                         Mumbai, Maharashtra 400058 </p>
                </div>
                <div>
                    <img src="img/call.png" alt="img_missing" />
                    <p><a href="tel:18002665007">18002665007</a></p>
                </div>
                <div>
                    <img src="img/mes.png" alt="img_missing" />
                    <p><a href="mailto:support@stepapp.ai">support@stepapp.ai</a></p>
                </div> 
            </div> 
            </div>  
            </div>  
            </SiteLayout>
        )
    }
}
